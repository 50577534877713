



import { handleRefreshToken, logout } from '@ntpkunity/controls-ums';
import { SETTINGS, errorStatusCodes } from "@shared/constants";
import { networkError } from "@shared/constants/messages";
import { token } from "@shared/utils";
import axios, { type AxiosInstance } from 'axios';
import { LocalStorage } from "shared/helper/browserStorage";
import { AppError } from "./errors";

interface Setting {
  baseUrl?: string;
}

class Http {
  _localStorageService: LocalStorage;
  _baseUrl: string;
  _token: string | null;
  _x_api_key: string | null;
  _axiosInstance: AxiosInstance;

  constructor(settings?: Setting) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this._localStorageService = new LocalStorage();
    if(token){
      this._token = `Bearer ${token.access_token}`;
    }
    this._x_api_key = params.get("x-api-key");
    this._baseUrl = settings?.baseUrl ?? process.env.API_GATEWAY ?? '';

    // Initialize axios instance with base URL and default headers
    this._axiosInstance = axios.create({
      baseURL: this._baseUrl,
      headers: {
        Authorization: this._token ?? '',
        ...(this._x_api_key && { "x-api-key": this._x_api_key })  // Conditionally include x-api-key if it exists
      }
    });

    // Setting up interceptor
    this._axiosInstance.interceptors.response.use(
      response => response,  // Success case, simply return response
      async error => {  // Error case, attempt token refresh
        await handleRefreshToken(error, this._axiosInstance, logout);
        return Promise.reject(error);
      }
    );
  }

  static createConnection(settings?: Setting): Http {
    return new Http(settings);
  }

  public async get<T>(url: string, params?: any, options?: any): Promise<T> {
    try {
      const response = await this._axiosInstance.get<T>(url, { params, headers: { Authorization: this._token ?? '' } });
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.data?.message ?? networkError;
      const status = error?.response?.status;

      throw new AppError(
        `Http.get(${this._baseUrl}/${url})`,
        errorStatusCodes.includes(status) ? message : networkError,
        error.code,
        error.response.status,
        { params, options, error }
      );
    }
  }

  public getPaginated(url: string, params?: any, options?: any): any {
    try {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        "NOT_IMPLEMENTED"
      );
    } catch (error) {
      throw new AppError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        error.message,
        error.code,
        error.response?.status,
        { params, options }
      );
    }
  }

  public async post<T>(url: string, body?: any, options?: any): Promise<T> {
    try {
      const response = await this._axiosInstance.post<T>(url, body, {
        headers: {
          Authorization: this._token ?? '',
          ...(this._x_api_key && { "x-api-key": this._x_api_key })
        }
      });
      return response.data;
    } catch (error) {
      const { data, status } = error.response;
      throw new AppError(
        `Http.post(${this._baseUrl}/${url})`,
        errorStatusCodes.includes(status) ? data?.message : error.message,
        error.code,
        error.response?.status,
        { body, options, error }
      );
    }
  }

  public async put<T>(url: string, body?: any, options?: any): Promise<T> {
    try {
      const response = await this._axiosInstance.put<T>(url, body, {
        headers: {
          Authorization: this._token ?? '',
          ...(this._x_api_key && { "x-api-key": this._x_api_key })
        }
      });
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.data?.message ?? networkError;
      const status = error?.response?.status;
      throw new AppError(
        `Http.put(${this._baseUrl}/${url})`,
        errorStatusCodes.includes(status) ? message : networkError,
        error.code,
        error.response?.status,
        { body, options, error }
      );
    }
  }


  public async patch<T>(url: string, body?: any, options?: any): Promise<T> {
    try {
      const response = await this._axiosInstance.patch<T>(url, body, {
        headers: {
          Authorization: this._token ?? '',
          ...(this._x_api_key && { "x-api-key": this._x_api_key })
        }
      });
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.data?.message ?? networkError;
      const status = error?.response?.status;
      throw new AppError(
        `Http.patch(${this._baseUrl}/${url})`,
        errorStatusCodes.includes(status) ? message : networkError,
        error.code,
        error.response?.status,
        { body, options, error }
      );
    }
  }

  public async delete<T>(url: string, params?: any, options?: any): Promise<T> {
    try {
      const response = await this._axiosInstance.delete<T>(url, {
        headers: {
          Authorization: this._token ?? '',
          ...(this._x_api_key && { "x-api-key": this._x_api_key })
        },
        params
      });
      return response.data;
    } catch (error) {
      const message = error?.response?.data?.data?.message ?? networkError;
      const status = error?.response?.status;
      throw new AppError(
        `Http.delete(${this._baseUrl}/${url})`,
        errorStatusCodes.includes(status) ? message : networkError,
        error.code,
        error.response?.status,
        { params, options, error }
      );
    }
  }

}
export default Http;
